/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // RESPONSIVE NAVIGATION
        jQuery(window).ready(function() {

          $('.nav-resp').hide();

          $('.burger').click(function(){
            $('.nav-resp').slideToggle('slow');
          });

        });

        // NAVIGATION DROPDOWNS
        jQuery(window).ready(function() {

          // $('.nav-primary .menu-item-has-children .sub-menu').hide();

          $('.nav-primary .menu-item-has-children').children('ul.sub-menu').hide();

          $('.nav-primary .menu-item-has-children > a').removeAttr('href');

          $('.nav-primary .menu-item-has-children').on('click', function(){

            var element = $(this);

            if (element.hasClass('open')) {
              element.removeClass('open');
              element.children('ul.sub-menu').slideUp(400);
            }
            else {
              element.addClass('open');
              element.siblings('.menu-item-has-children').removeClass('open');
              element.siblings('.menu-item-has-children').children('ul.sub-menu').slideUp(400);
              element.children('ul.sub-menu').slideDown(400);
            }

          });

        });

        // jQuery(window).ready(function() {

        //   $('.nav-full .menu-item-has-children .sub-menu').hide();

        //   $('.nav-full .menu-item-has-children').hover(
        //     function(){
        //       $(this).find('.sub-menu').stop().show();
        //     },
        //     function(){
        //       $(this).find('.sub-menu').stop().hide();
        //     }
        //   );

        // });

        // FLEXSLIDER
        jQuery(window).ready(function($) {
          
          $('.flexslider').flexslider({
            animation: "fade",
            slideshow: true,
            controlNav: false,
            directionNav: false,
            animationSpeed: 800,
          });

          // $('#carousel.flexslider').flexslider({
          //   animation: "slide",
          //   controlNav: false,
          //   animationLoop: true,
          //   slideshow: false,
          //   itemWidth: 230,
          //   itemMargin: 0,
          //   asNavFor: '#slider.flexslider'
          // });
        
        });

        // MAGNIFIC POPUP
        jQuery(window).ready(function($){

          $('.popup').magnificPopup({
            type: 'iframe'
          });

        });

        // SLICK CAROUSEL
        jQuery(window).ready(function($){
          $('.slick').slick({
            
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 2,

            dots: true,
            arrows: true,
            speed: 1200,

            lazyLoad: 'ondemand',

            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]

          });
        });

        // // ACCORDION
        // jQuery(window).ready(function(){

        //   $('#accordion').accordion({
        //     collapsible: true,
        //     heightStyle: "content"
        //   });
        //   $('#accordion').accordion().removeClass('ui-accordion-header');

        // });

        // // ISOTOPE
        // jQuery(window).ready(function($){

        //   // Init Isotope
        //   var $grid = $('#grid-isotope').isotope({
        //     // Options
        //     itemSelector: '.grid-item'
        //   });

        //   // show all items
        //   $grid.isotope({ filter: '*' });


        //   // filter items on button click
        //   $('.filter-button-group').on( 'click', 'span', function() {
        //     var filterValue = $(this).attr('data-filter');
        //     $grid.isotope({ filter: filterValue });
        //   });

        //   // change is-checked class on buttons
        //   $('.filter-button-group').each( function( i, spanGroup ) {
        //     var $spanGroup = $( spanGroup );
        //     $spanGroup.on( 'click', 'span', function() {
        //       $spanGroup.find('.is-checked').removeClass('is-checked');
        //       $( this ).addClass('is-checked');
        //     });
        //   });

        //   // // Init Isotope - Products Archive
        //   // var $productgrid = $('ul.products').isotope({
        //   //   // Options
        //   //   itemSelector: 'li.product',
        //   //   percentPosition: true,
        //   //   columnWidth: 'li.product'
        //   // });

        // });

        // END
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
